import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation, useParams } from 'react-router-dom'
import { Card, CardContent, Typography, TextField, Box, Button } from '@mui/material'
import rewardsProvider from '../../../config/rewardsProvider'
import _, { toInteger, toNumber } from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useSnackbar } from 'notistack'
import { useRedirect } from 'react-admin'

export default (props) => {
    const navigate = useNavigate()
    const redirect = useRedirect()
    const confirm = useConfirm()
    const { enqueueSnackbar } = useSnackbar()
    const { profitProductId } = useParams()

    const [surname, setSurname] = useState('')
    const [numtel, setNumtel] = useState('')
    const [email, setEmail] = useState('')

    const [participants, setParticipants] = useState([])

    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const rawData = params.get('data')
    const orderData = rawData ? JSON.parse(rawData) : null

    React.useEffect(() => {
        const _participants = []
        if (orderData?.type == 1) {
            for (let index = 0; index < orderData.quantity; index++) {
                _participants[index] = { name: null, email: null, phone: null }
            }
            setParticipants(_participants)
        }

    }, [])

    const confirmOrder = (e) => {
        e.preventDefault()

        const _orderData = {
            name: surname,
            phoneNumber: numtel,
            email: email,
            product: profitProductId,
            quantity: toInteger(orderData?.quantity),
            amount: toNumber(orderData.priceFinal),
            points: orderData?.pointsUsed,
            selectedAttributes: JSON.stringify(orderData?.selectedAttributes),
            department: '/departments/' + orderData.department,
            participants: participants
        }

        rewardsProvider
            .createOrder(_orderData)
            .then((r) =>
                confirm({
                    title: 'Zamówienie zostało pomyślnie złożono',
                    description: `Twoje zamówienie zostało złożone i niedługo przesłane do realizacji.`,
                    confirmationText: 'Przejdź na stronę główną',
                    confirmationButtonProps: {
                        variant: 'contained',
                        color: 'success',
                    },
                    allowClose: false,
                    hideCancelButton: true,
                }).then(() => {
                    redirect('/')
                })
            )
            .catch((e) =>
                enqueueSnackbar(`Wystąpił błąd: ${e}`, {
                    variant: 'error',
                    autoHideDuration: 10000,
                })
            )
    }

    return (
        <Card>
            <CardContent>
                <form onSubmit={confirmOrder}>
                    <Typography gutterBottom variant="h4">
                        Zamawiasz: {orderData?.name} [
                        {_.isObject(orderData?.selectedAttributes) &&
                            Object.entries(orderData?.selectedAttributes)
                                .map(([key, value]) => `${key} ${value}`)
                                .join(',')}
                        ]
                    </Typography>

                    <Typography gutterBottom variant="h5">
                        Podsumowanie:
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        Wartość zamówienia: {orderData?.priceFinal || 0} zł
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        Wartość PKT: {orderData?.pointsUsed || 0} pkt
                    </Typography>
                    <Typography gutterBottom variant="body1">
                        Ilość sztuk: {orderData?.quantity || 0} szt
                    </Typography>

                    <Typography gutterBottom variant="h5">
                        Dane zamawiającego:
                    </Typography>

                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
                        <TextField
                            variant="outlined"
                            label="Imię i nazwisko"
                            type="text"
                            value={surname}
                            onChange={(e) => setSurname(e.target.value)}
                        />

                        <TextField
                            variant="outlined"
                            label="Numer telefonu"
                            type="tel"
                            value={numtel}
                            onChange={(e) => setNumtel(e.target.value)}
                        />
                        <TextField variant="outlined" label="E-mail" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Box>

                    {orderData.type == 1 && (
                        <>
                            <Typography gutterBottom variant="h5">
                                Dane uczestników:
                            </Typography>

                            {participants.map((p, i) => (
                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '300px', marginBottom: "20px" }}>
                                    <TextField
                                        variant="outlined"
                                        label="Imię i nazwisko"
                                        type="text"
                                        value={p.name}
                                        onChange={(e) => {
                                            participants[i].name = e.target.value
                                            setParticipants([...participants])
                                        }}
                                    />

                                    <TextField
                                        variant="outlined"
                                        label="Numer telefonu"
                                        type="tel"
                                        value={p.phone}
                                        onChange={(e) => {
                                            participants[i].phone = e.target.value
                                            setParticipants([...participants])
                                        }}
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="E-mail"
                                        type="email"
                                        value={p.email}
                                        onChange={(e) => {
                                            participants[i].email = e.target.value
                                            setParticipants([...participants])
                                        }}
                                    />
                                </Box>
                            ))}
                        </>
                    )}

                    <Box sx={{ display: 'flex', width: '500px', justifyContent: 'space-between' }}>
                        <Button variant="outlined" color="warning" onClick={() => navigate(-1)}>
                            Wstecz
                        </Button>
                        <Button type="submit" variant="contained" color="primary">
                            Zamawiam i placę
                        </Button>
                    </Box>
                </form>
            </CardContent>
        </Card>
    )
}
