import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Show, SimpleShowLayout, usePermissions, WithRecord } from 'react-admin';
import './style.css'
import PermissionHandler from '../../config/PermissionHandler';
import DOMPurify from 'dompurify';
import { CustomActionButtonDelete } from '../CustomFields/CustomActionButtonsField';
export default () =>{
  const {id} = useParams()
  
  const {permissions} = usePermissions()

  return <Show id={`/refund_menus/${id}`} title={' '} emptyWhileLoading sx={{fontSize: '1rem'}} actions={null}>
      <SimpleShowLayout>
      { PermissionHandler(permissions,'RefundMenu','Create') && 
      <>
        <Button sx={{ marginRight: 2 }} variant="contained" color='warning' href={`#/refund_menus/${id}`}>Edytuj</Button>
        <CustomActionButtonDelete/>
      </>
      }
        <WithRecord render={record => {if(record.contentType	 == 'link') return  <> Link przekierujący użytkownika - <a rel='noopener' target="_blank" href={record.link }>{record.link}</a></>}}/>
        <WithRecord label='' render={record =>  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(record.body, { ADD_ATTR: ['target'] })}}></div>}/>
      </SimpleShowLayout>
    </Show>
}