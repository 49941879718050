import {
  List,
  TextField,
  FunctionField,
  DateField,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import CustomDataGrid from '../CustomElements/CustomDataGrid';
import EmptyList from '../EmptyList';
import { CustomPagination } from '../CustomElements/CustomPagination';
export default (params) => {

  return <List
    pagination={<CustomPagination />}
    title={'Logi'}
    actions={null}
    filters={[
      <TextInput alwaysOn variant="outlined" resettable label="Treść loga" source="message" />,
      <ReferenceInput source='type' reference='log_types' alwaysOn>
        <AutocompleteInput 
          variant='outlined' 
          optionText='name' 
          label="Typ loga" 
          sx={{minWidth: 250}}
          filterToQuery={(searchText) => ({
            name: searchText,
          })} 
        />
      </ReferenceInput>,
      <SelectInput
        label="Poziom loga"
        source="level"
        variant="outlined"
        resettable
        choices={[
          { id: 'info', name: 'info' },
          { id: 'error', name: 'error' },
        ]}
        alwaysOn
      />,
    ]}
    empty={<EmptyList title="logów" add={false} />}
  >
    <CustomDataGrid>
      <TextField source='message' label="Treść loga" sortable={false} />
      <TextField source='type.name' label="Typ loga" sortable={false} />
      <TextField source='level' label="Poziom" sortable={false} />
      <DateField source='createdAt' label='Data' sortable={false} showTime />
      <FunctionField label="Wiadomość błedu" render={(record) => record.errorMsg ?? 'brak'} sortable={false} />
    </CustomDataGrid>
  </List>
};
