import { iconChoices, rolesChoices } from "./RefundationMenuCreate";

import React from 'react'

import { SimpleForm, TextInput, required, SelectInput, Edit, SelectArrayInput, ReferenceArrayInput, RadioButtonGroupInput, FormDataConsumer } from 'react-admin';
import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars';
import TinyMCEInput from '../CustomInputs/TinyMCEInput';
import { useParams } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (props) => {
  const requiredField = required('Pole wymagane')
  const { id } = useParams()
  const optionRenderer = choice => <FontAwesomeIcon icon={choice.icon} />
  
  return <Edit id={`/refund_menus/${id}`} title={' '} actions={null}>
      <SimpleForm toolbar={<PostBottomToolbarEdit isDeleteAvailable/>}>
        <TextInput source='header' variant='outlined' label='Tytuł' required={requiredField}/>
        <SelectArrayInput variant='outlined' label="Widoczność dla:" source='roles' choices={rolesChoices} />
        <ReferenceArrayInput source='partners' reference='partners'>
          <SelectArrayInput variant='outlined' label='Partnerzy' optionText='name'/>
        </ReferenceArrayInput>
        <SelectInput source='icon' variant='outlined' label="Ikona menu" optionText={optionRenderer} required={requiredField} choices={iconChoices} />
        <RadioButtonGroupInput source='contentType' defaultValue={'content'} label="Typ kafelka" choices={[
          {id: 'content', name: 'Zawartość'},
          {id: 'link', name: 'Link'}
        ]}/>
        <FormDataConsumer>
            {({formData})=> {
              if( formData.contentType == 'content') return <div className="tiny">
              <TinyMCEInput source='body' className="tiny"/>
              </div>
              if(formData.contentType == 'link') return <TextInput source='link' label='link' variant='outlined' />
            }}
        </FormDataConsumer>
      </SimpleForm>
    </Edit> 
}