import * as React from 'react'
import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import { ToggleButton, ToggleButtonGroup, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material'
import { Show, SimpleShowLayout, useShowController, FunctionField, useRedirect, useGetOne } from 'react-admin'
import InputSlider from '../CustomElements/InputSlider'
import { useSnackbar } from 'notistack'
import { CustomTitle } from '../../CustomElements/CustomTitle'
import { useLocation } from 'react-router-dom'
import { toInteger } from 'lodash'
import ImageGallery from 'react-image-gallery'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import CloseIcon from '@mui/icons-material/Close'

const orangeButton = {
    marginTop: '20px',
    marginBottom: '20px',
    height: '40px',
    background: '#d4731c',
    color: '#fff',
    border: '0px',
    width: '150px',
}

const Fullscreen = React.memo(({ isFullscreen, onClick }) => {
    return (
        <button type="button" className="image-gallery-icon image-gallery-fullscreen-button" onClick={onClick} aria-label="Open Fullscreen">
            {!isFullscreen ? <OpenInFullIcon color="primary" fontSize="large" /> : <CloseIcon fontSize="large" />}
        </button>
    )
})

export default (props) => {
    const { record } = useShowController()
    const redirect = useRedirect()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [selectedAttributes, setSelectedAttributes] = useState({})
    const [quantity, setQuantity] = useState(1)
    const [pointsUsed, setPointsUsed] = useState(0)
    const [pointsUserLimit, setPointsUserLimit] = useState(0)
    const [department, setDepartment] = useState(null)
    const [discountedPrice, setDiscountedPrice] = useState(record?.price || 0)
    const [attributeError, setAttributeError] = useState(false)
    const [departmentError, setDepartmentError] = useState(false)

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const { data: pointsData, isPending, error } = useGetOne('', { id: 'pk_users/get_points_data' })

    useEffect(() => {
        if (record?.price) {
            setDiscountedPrice(record.price * quantity)
        }
        if (pointsData?.type == 2) {
            setPointsUserLimit(pointsData?.institution)
        }
        if (params?.get('quantity')) {
            setQuantity(toInteger(params?.get('quantity')))
        }
    }, [record, pointsData?.type, params?.get('quantity')])

    const handleChange = (attribute, newValue) => {
        setSelectedAttributes((prev) => ({ ...prev, [attribute]: newValue }))
    }

    const formOrder = () => {
        if (record?.attributes && Object.entries(selectedAttributes).length != record?.attributes?.length) {
            enqueueSnackbar('Należy wybrać atrybuty', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            setAttributeError(true)
            return
        }

        if(pointsData && pointsData?.type == 1 && !department){
            enqueueSnackbar('Należy wybrać oddział', {
                variant: 'error',
                autoHideDuration: 5000,
            })
            setDepartmentError(true)
            return
        }

        const orderData = {
            name: record?.name,
            id: record?.id,
            quantity,
            pointsUsed: pointsUsed,
            points: record?.points,
            price: record?.price,
            priceFinal: discountedPrice,
            selectedAttributes: selectedAttributes,
            type: record?.type,
            department: department,
        }

        redirect(`/profit_products/${encodeURIComponent(record.id)}/new_order?data=${encodeURIComponent(JSON.stringify(orderData))}`)
    }

    if (!record) {
        return
    }

    return (
        <Show {...props} title={<CustomTitle resourceName="nagroda" fields={['name']} />} actions={null}>
            <SimpleShowLayout>
                <Box className="row" style={{ margin: '5px' }}>
                    <Typography variant="h4">{record?.name}</Typography>
                    <hr />
                    <Box className="col" style={{ width: '50%' }}>
                        <FunctionField
                            label="Zdjęcie"
                            render={({ imgUrls }) =>
                                imgUrls?.length > 0 ? (
                                    <ImageGallery
                                        items={imgUrls
                                            ?.sort((i1, i2) => (i1.isMain ? -1 : i1.sequence) - (i2.isMain ? -1 : i2.sequence))
                                            ?.map((img) => ({ original: img?.url, thumbnail: img?.url, originalHeight: '400px' }))}
                                        thumbnailPosition="right"
                                        showPlayButton={false}
                                        showNav={false}
                                        renderFullscreenButton={(onClick, isFullscreen) => (
                                            <Fullscreen onClick={onClick} isFullscreen={isFullscreen} />
                                        )}
                                    />
                                ) : (
                                    <img src="/img/reward_default.png" />
                                )
                            }
                        />
                    </Box>

                    <Box className="col" style={{ width: '50%' }}>
                    <Typography variant='h6' gutterBottom>Cena PLN: {record?.price}</Typography>
                        <Typography variant='h6' gutterBottom>Cena PKT: {record?.points}</Typography>

                        {record?.attributes?.map(({ attributeName, attributeValues }) => (
                            <Box sx={{ margin: '10px 0' }}>
                                <Typography variant='h6' gutterBottom>{attributeName} *</Typography>
                                <ToggleButtonGroup
                                    color="primary"
                                    value={selectedAttributes[attributeName]}
                                    exclusive
                                    onChange={(event, newValue) => handleChange(attributeName, newValue)}
                                    sx={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        rowGap: '8px',
                                        '& .MuiToggleButton-root': {
                                            width: '90px',
                                            height: '50px',
                                            color: 'white',
                                            backgroundColor: '#240a51',
                                            margin: '0 8px',
                                            borderRadius: '8px',
                                            '&:hover': {
                                                backgroundColor: '#3d0f8d',
                                            },
                                            '&.Mui-selected': {
                                                backgroundColor: '#d4731c',
                                                color: 'white',
                                                '&:hover': {
                                                    backgroundColor: '#ad5e17',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {Array.isArray(attributeValues) &&
                                        attributeValues.length > 0 &&
                                        attributeValues.map(({ value }, index) => (
                                            <ToggleButton
                                                onClick={() => setAttributeError(false)}
                                                sx={{ border: attributeError ? '3px solid #d32f2f' : 'unset', fontSize: value.length > 20 ? "10px" : "15px" }}
                                                key={index}
                                                value={value}
                                            >
                                                {value}
                                            </ToggleButton>
                                        ))}
                                </ToggleButtonGroup>
                            </Box>
                        ))}

                        {!isPending && pointsData && pointsData?.type == 1 && (
                            <Box>
                                <FormControl variant="outlined" sx={{ minWidth: 300 }}>
                                    <InputLabel>Wybierz oddział</InputLabel>
                                    <Select
                                        label="Wybierz oddział"
                                        onChange={(e, c) => {
                                            setPointsUserLimit(e.target.value)
                                            setDepartment(c.props.department)
                                            setDepartmentError(false);
                                        }}
                                        error={departmentError}
                                    >
                                        {pointsData?.departments?.map(({ name, id, points }) => (
                                            <MenuItem disabled={!points} value={points} department={id}>
                                                {name} (ID: {id}), {points ?? 0} pkt
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        )}

                        <InputSlider
                            price={record?.price || 0}
                            pointsLimit={record?.points || 0}
                            quantity={quantity}
                            onQuantityChange={setQuantity}
                            pointsUsed={pointsUsed}
                            onPointsUsedChange={setPointsUsed}
                            discountedPrice={discountedPrice}
                            onDiscountedPriceChange={setDiscountedPrice}
                            pointsUserLimit={pointsUserLimit}
                            maxQuantity={record?.unlimited ? undefined : record?.quantity}
                        />

                        <Button sx={{ margin: '10px 0' }} variant="contained" color="primary" onClick={formOrder}>
                            Zamawiam
                        </Button>
                    </Box>

                    <Box sx={{ margin: '10px 0' }}>
                        <hr />
                    </Box>

                    <Typography variant="h5">Opis nagrody</Typography>
                    <Box dangerouslySetInnerHTML={{ __html: record?.description }} />
                </Box>
            </SimpleShowLayout>
        </Show>
    )
}
