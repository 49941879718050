import React from 'react'
import { Edit, SimpleForm, TextInput, required, BooleanInput, useRecordContext, ReferenceInput, AutocompleteInput } from 'react-admin'
import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars'

const CustomTitle = () => {
    const record = useRecordContext()
    return record ? `Zgoda ${record.name}` : null
}
const requiredField = required()

export default (props) => {
    return (
        <Edit {...props} title={<CustomTitle />}>
            <SimpleForm {...props} toolbar={<PostBottomToolbarEdit />}>
                <div className="d-flex w-100 justify-content-around">
                    <div class="col-sm-5">
                        <TextInput source="name" label="Nazwa" variant="outlined" fullWidth validate={requiredField} />
                        <TextInput source="content" label="Treść" variant="outlined" rows={4} multiline fullWidth validate={requiredField} />
                        <span>Zostaw to pole puste jeśli tworzysz zgodę dla wszystkich partnerów</span>
                        <ReferenceInput source="partner" reference="partners" fullWidth>
                            <AutocompleteInput
                                variant="outlined"
                                label="Partner"
                                optionText={'name'}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                emptyText="Wszystkie"
                            />
                        </ReferenceInput>
                        <BooleanInput source="status" label="Status" variant="outlined" />
                    </div>
                </div>
            </SimpleForm>
        </Edit>
    )
}
