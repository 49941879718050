import React, { useEffect } from 'react'
import {
    useRecordContext,
    useDataProvider,
    useRefresh,
    useCreateController,
    CreateContextProvider,
    SimpleForm,
    ImageInput,
    ImageField,
    SaveButton,
    Toolbar,
} from 'react-admin'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardActions,
    CardContent,
    FormControlLabel,
    Modal,
    Pagination,
    Switch,
} from '@mui/material'

export default ({ open, setOpen }) => {
    const record = useRecordContext()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [isLoadingProfitProductImageCreation, setIsLoadingProfitProductImageCreation] = React.useState(false)
    const [debounce, setDebounce] = React.useState()
    const [refreshImgs, setRefreshImgs] = React.useState(false)
    const [page, setPage] = React.useState(1)
    const [total, setTotal] = React.useState(0)
    const perPage = 6

    const [searchedMediaObjects, setSearchedProducts] = React.useState([])
    const [searchLoading, setSearchLoading] = React.useState(false)
    const [searchFileName, setSearchFileName] = React.useState('')

    const addProduct = (profitProductId, mediaObjectId, sequence, main) => {
        setIsLoadingProfitProductImageCreation(true)
        dataProvider
            .create('profit_product_images', {
                data: {
                    profitProduct: profitProductId,
                    mediaObject: mediaObjectId,
                    sequence: sequence,
                    main: main,
                },
            })
            .then((r) => {
                enqueueSnackbar('Zdjęcie zostało przypisane', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            })
            .catch((e) =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => {
                setIsLoadingProfitProductImageCreation(false)
                refresh()
            })
    }

    const submitSearch = () => {
        setSearchLoading(true)
        setSearchedProducts([])
        dataProvider
            .getList('media_objects', {
                pagination: { page: page, perPage: perPage },
                sort: { field: 'id', order: 'DESC' },
                filter: {
                    filePath: searchFileName,
                },
            })
            .then(({ data, total }) => {
                if (Array.isArray(data)) {
                    setSearchedProducts(data)
                    setTotal(total)
                } else {
                    enqueueSnackbar('Wystąpił błąd', {
                        variant: 'error',
                        autoHideDuration: 5000,
                    })
                }
            })
            .catch((e) => {
                enqueueSnackbar(e?.message ?? 'Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            })
            .finally(() => setSearchLoading(false))
    }

    useEffect(() => {
        clearTimeout(debounce)
        setDebounce(
            setTimeout(() => {
                submitSearch()
            }, 1000)
        )
    }, [searchFileName, page, refreshImgs])

    const ImageCreate = (props) => {
        const createControllerProps = useCreateController(props)
        const {
            basePath, // deduced from the location, useful for action buttons
            record, // empty object, unless some values were passed in the location state to prefill the form
            redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
            resource, // the resource name, deduced from the location. e.g. 'posts'
            save, // the create callback, to be passed to the underlying form as submit handler
            saving, // boolean that becomes true when the dataProvider is called to create the record
            version, // integer used by the refresh feature
        } = createControllerProps
        return (
            <CreateContextProvider value={createControllerProps}>
                <div>
                    {React.cloneElement(props.children, {
                        basePath,
                        record,
                        redirect,
                        resource,
                        save,
                        saving,
                        version,
                    })}
                </div>
            </CreateContextProvider>
        )
    }

    const Inputs = ({ mo, close }) => {
        const [main, setMain] = React.useState(false)
        const [sequence, setSequence] = React.useState(0)

        return (
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                }}
            >
                <CardContent>
                    <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between' }}>
                        <TextField
                            type="number"
                            value={sequence}
                            onChange={(e) => setSequence(parseInt(e.target.value))}
                            label="Kolejność"
                            variant="outlined"
                            className="w-25"
                            min={1}
                        />
                        <FormControlLabel control={<Switch checked={main} onChange={(e) => setMain(e.target.checked)} />} label="Zdjęcie główne" />
                    </Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => addProduct(record.id, mo.id, sequence, main)}
                        style={{ width: '100%', height: 'auto' }}
                        disabled={isLoadingProfitProductImageCreation}
                    >
                        Dodaj
                    </Button>
                    <Button
                        variant="outlined"
                        color="warning"
                        onClick={close}
                        style={{ width: '100%', height: 'auto' }}
                        disabled={isLoadingProfitProductImageCreation}
                    >
                        Anuluj
                    </Button>
                </CardContent>
            </Card>
        )
    }

    const ActionsModalContent = ({ mo }) => {
        const [openA, setOpenA] = React.useState(false)
        const handleOpen = () => {
            setOpenA(true)
        }
        const handleClose = () => {
            setOpenA(false)
        }

        return (
            <CardContent sx={{ display: 'flex', flexDirection: 'column', '&:hover': { backgroundColor: '#c3e6cb' } }}>
                <img onClick={handleOpen} src={`${mo.contentUrl}`} width="200px" height="auto" />
                <Typography variant="caption" sx={{ lineBreak: 'anywhere', width: '200px' }}>
                    {mo.filePath}
                </Typography>
                <Modal open={openA} onClose={handleClose}>
                    <Inputs mo={mo} close={handleClose} />
                </Modal>
            </CardContent>
        )
    }

    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Card
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    maxHeight: 600,
                    overflowY: 'scroll',
                }}
            >
                <Accordion>
                    <AccordionSummary id="panel-create">
                        <Typography>Dodaj nowe zdjęcie</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ImageCreate
                            {...{
                                basePath: `/media_objects`,
                                resource: `media_objects`,
                            }}
                        >
                            <SimpleForm
                                toolbar={
                                    <Toolbar>
                                        <SaveButton
                                            type="button"
                                            mutationOptions={{
                                                onSuccess: (response) => {
                                                    setRefreshImgs(!refreshImgs)
                                                    enqueueSnackbar('Zdjęcie zostało dodane do repozytorium', {
                                                        variant: 'success',
                                                        autoHideDuration: 5000,
                                                    })
                                                },
                                            }}
                                        />
                                    </Toolbar>
                                }
                            >
                                <ImageInput source="file" label="Dodaj nowe zdjęcie" accept="image/*">
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </SimpleForm>
                        </ImageCreate>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary id="panel-choose">
                        <Typography>Wybierz zdjęcie z repozytorium</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            value={searchFileName}
                            onChange={(e) => setSearchFileName(e.target.value)}
                            label="Nazwa pliku"
                            variant="outlined"
                            className="w-100"
                        />
                        <Box sx={{ margin: '10px 0' }}>
                            {searchedMediaObjects?.map((_mo) => (
                                <Card
                                    sx={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        maxWidth: '250px',
                                        marginRight: '5px',
                                    }}
                                >
                                    <ActionsModalContent mo={_mo} />
                                </Card>
                            ))}
                        </Box>
                        <Pagination count={Math.ceil(total / perPage)} variant="outlined" page={page} onChange={(e, v) => setPage(v)} />
                    </AccordionDetails>
                </Accordion>
            </Card>
        </Modal>
    )
}
