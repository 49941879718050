import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { useGetList, usePermissions } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalculator, faCommentDollar, faFileContract, faInfoCircle, faPhone, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { ReactSVG } from 'react-svg'

import PermissionHandler from '../../config/PermissionHandler'

import { iconChoices } from "./RefundationMenuCreate";

const menuElementContainer = {
    display: 'flex',
    width: '100%',
    minWidth: 250,
    minHeight: 200,
    maxHeight: 100,
    margin: 10,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2em',
    textDecoration: 'none',
}

const fontStyle = {
    padding: '2%',
    color: '#d4731c',
    textTransform: 'normal',
    textAlign: 'center',
    fontSize: '1rem',
}

export default (props) => {
    const { permissions } = usePermissions()
    const {data, isLoading} = useGetList('refund_menus')

    const customPermissionHandler = (requestPermissions = [], userPermissions = permissions) => {
        if(userPermissions.split(',').includes('ROLE_SUPER_ADMIN')) return true;
        let result = false
        userPermissions.split(',').map((x)=> {if (requestPermissions.includes(x)) result = true } )
        return result
    }
    const partnerHandler = (reqPar = [], userPartner) =>{
        reqPar = reqPar.map((x) => x.split('/')[2])        
        if(permissions.split(',').includes('ROLE_SUPER_ADMIN')) return true;
        if(reqPar.includes(userPartner)) return true
        return false
    }

    return (
        <Card>
            <CardContent>
                <div className="page-content m-2">
                    <div className="row">
                        {PermissionHandler(permissions, 'RefundUpload', 'Read') && (
                            <div className="col">
                                <Link to="/documents" style={menuElementContainer} className="dash-card">
                                    <FontAwesomeIcon icon={faFileContract} inverse />
                                    <span style={fontStyle}>Dokumenty rozliczeniowe</span>
                                </Link>
                            </div>
                        )}
                        {['TZMO', 'SMP', 'Citonet Szczecin', 'Citonet Łódź'].includes(localStorage.getItem('partnerName')) && (
                            <div className="col">
                                <Link to="/pickup-points-refund" style={menuElementContainer} className="dash-card">
                                    <ReactSVG src="/icon-refund.svg" />
                                    <span style={fontStyle}>Status Rozliczeń</span>
                                </Link>
                            </div>
                        )}
                        
                        {PermissionHandler(permissions, 'RefundCalc', 'Read') && (
                            <div className="col">
                                <Link to="/calculator" style={menuElementContainer} className="dash-card">
                                    <FontAwesomeIcon icon={faCalculator} inverse />
                                    <span style={fontStyle}>Kalkulator dofinansowania</span>
                                </Link>
                            </div>
                        )}
                        {!isLoading && data?.map( r => 
                            customPermissionHandler(r.roles,permissions) && partnerHandler(r.partners, localStorage.getItem('partnerId')) && ((r.contentType=='content' || PermissionHandler(permissions,'RefundMenu','Update')) ? <div className="col">
                                <Link to={`${r.id}/show`} style={menuElementContainer} className="dash-card">
                                    <FontAwesomeIcon icon={iconChoices?.find((icon)=> icon.id == r.icon)?.icon} inverse />
                                    <span style={fontStyle}>{r.header}</span>
                                </Link>
                            </div> : <div className="col"> 
                                    <a
                                    href={r.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={menuElementContainer}
                                    className="dash-card"
                                >
                                    <FontAwesomeIcon icon={iconChoices.find((icon)=> icon.id == r.icon).icon} inverse />
                                    <span style={fontStyle}>{r.header}</span>
                                </a> 
                            </div>
                            )
                        )}
                        {PermissionHandler(permissions,'RefundMenu','Create') && (
                            <div className='col'>
                                <Link to="create" style={menuElementContainer} className="dash-card">
                                    <FontAwesomeIcon icon={faPlusCircle} inverse />
                                    <span style={fontStyle}>Dodaj</span>
                                </Link>

                            </div>
                        )}
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}
