import React from 'react'
import { useRecordContext, useDataProvider, useRefresh } from 'react-admin'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, plPL } from '@mui/x-data-grid'
import { useSnackbar } from 'notistack'
import { FormControlLabel, Switch, Skeleton, TextField, Button, Box } from '@mui/material'

export default () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()

    const [isLoading, setIsLoading] = React.useState(false)

    const Inputs = ({ img }) => {
        const [main, setMain] = React.useState(img.isMain)
        const [sequence, setSequence] = React.useState(img.sequence)

        return [
            <TextField
                type="number"
                value={sequence}
                onChange={(e) => setSequence(parseInt(e.target.value))}
                label="Kolejność"
                variant="outlined"
                min={1}
            />,
            <FormControlLabel control={<Switch checked={main} onChange={(e) => setMain(e.target.checked)} />} label="Zdjęcie główne" />,

            <Button variant="contained" color="primary" onClick={() => updateImage(img.id, sequence, main, img.mediaObject, record.id )} disabled={isLoading}>
                Zapisz
            </Button>,
            <Button variant="outlined" color="error" onClick={() => removeImage(img?.id)} disabled={isLoading}>
                Usuń
            </Button>,
        ]
    }

    const columns: GridColDef[] = [
        {
            field: 'imgUrl',
            headerName: 'Zdjęcie',
            flex: 1,
            renderCell: (params) => {
                return <img height="100px" width="auto" src={params.row.url} />
            },
        },
        {
            field: 'add',
            headerName: 'Akcje',
            type: 'actions',
            flex: 2,
            getActions: (params: GridRowParams) => [<Inputs img={params.row} />],
        },
    ]

    const removeImage = (id) => {
        setIsLoading(true)
        dataProvider
            .delete('profit_product_images', {
                id: id,
            })
            .then((r) => {
                enqueueSnackbar('Zdjęcie zostało usunięte', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            })
            .catch((e) =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => {
                setIsLoading(false)
                refresh()
            })
    }

    const updateImage = (id, sequence, isMain, mediaObjectId, profitProductId) => {
        setIsLoading(true)
        dataProvider
            .update('profit_product_images', {
                id: id,
                data: { sequence: sequence, main: isMain, mediaObject: mediaObjectId, profitProduct: profitProductId },
            })
            .then((r) => {
                enqueueSnackbar('Zdjęcie zostało zaktualizowane', {
                    variant: 'success',
                    autoHideDuration: 5000,
                })
            })
            .catch((e) =>
                enqueueSnackbar('Wystąpił błąd', {
                    variant: 'error',
                    autoHideDuration: 5000,
                })
            )
            .finally(() => {
                setIsLoading(false)
                refresh()
            })
    }

    return (
        <Box className="mb-4">
            <Box
                style={{
                    width: '100%',
                    minHeight: '275px',
                    display: 'grid',
                }}
            >
                <DataGrid
                    rows={record?.imgUrls ?? []}
                    columns={columns}
                    getRowId={(row) => row?.id}
                    getRowHeight={() => 'auto'}
                    loading={!record}
                    disableColumnMenu
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'rgba(0,0,0,.07)',
                        },
                        '& .odd': {
                            backgroundColor: 'rgba(0,0,0,.02)',
                        },
                    }}
                    getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
                    slots={{
                        loadingOverlay: () => (
                            <Box>
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                                <Skeleton height={30} />
                            </Box>
                        ),
                    }}
                    localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25, 50, 100]}
                />
            </Box>
        </Box>
    )
}
