import * as React from 'react'
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    RadioGroup,
    Radio,
    FormControlLabel,
    FormGroup,
    Checkbox,
} from '@mui/material'
import { Title, useGetList, useStore } from 'react-admin'
import { faBuilding, faHandHoldingDollar, faListCheck, faWallet } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const btnContainer = {
    display: 'flex',
    flexDirection: 'column-reverse',
}

export default () => {
    const [orderType, setOrderType] = React.useState(0)
    const [value, setValue] = useStore('order_dps_preferences', false)

    const OrderType = ({ title, icon, type }) => {
        let style = { color: '#e18a3d', background: '#ffffff' }
        if (type == orderType) {
            style = { color: '#ffffff', background: '#e18a3d' }
        }

        return (
            <Card sx={{ width: 350, minHeight: 100, ...style }}>
                <CardActionArea sx={{ height: '100%' }} onClick={() => setOrderType(type)}>
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                        }}
                    >
                        <FontAwesomeIcon icon={icon} size="3x" />
                        <Typography variant="h6" textAlign="center" display="flex" flexDirection="column">
                            {title}
                            <hr
                                style={{
                                    width: '40%',
                                    alignSelf: 'center',
                                    opacity: 1,
                                    height: '5px',
                                    border: 'none',
                                    borderRadius: '5px',
                                    background: style.color,
                                }}
                            />
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }

    const StepperOrder = ({ callback }) => {
        const [activeStep, setActiveStep] = React.useState(0)
        const [invoice, setInvoice] = React.useState('')
        const [payment, setPayment] = React.useState('')
        const [delivery, setDelivery] = React.useState('')
        const [privateDps, setPrivateDps] = React.useState('')
        const [department, setDepartment] = React.useState({})

        const { data: departments } = useGetList('departments', { pagination: { page: 1, perPage: 100 } })

        React.useEffect(() => {
            setActiveStep(orderType > 0 ? 0 : -1)
            if (orderType == 3) {
                setInvoice('dps')
            } else {
                setInvoice('')
            }
        }, [orderType])

        const steps = [
            {
                label: 'Dofinansowanie DPS',
                _value: privateDps,
                _setValue: setPrivateDps,
                radio: [
                    { value: false, label: 'Tak' },
                    { value: true, label: 'Nie' },
                ],
            },
            {
                label: 'Sposób fakturowania',
                _value: invoice,
                _setValue: setInvoice,
                radio: [
                    { value: 'patient', label: 'Na podopiecznego' },
                    { value: 'dept', label: 'Na oddział' },
                    { value: 'dps', label: 'Na DPS' },
                ],
            },
            {
                label: 'Sposób odebrania',
                _value: delivery,
                _setValue: setDelivery,
                radio: [
                    { value: 'patient', label: 'Na podopiecznego' },
                    { value: 'dept', label: 'Na oddział' },
                    { value: 'dps', label: 'Na DPS' },
                ],
            },
            // {
            //     label: 'Sposób płatności',
            //     _value: payment,
            //     _setValue: setPayment,
            //     radio: [
            //         // { value: 'cash', label: 'Gotówka' },
            //         { value: 'transfer', label: 'Przelew' },
            //     ],
            // },
            {
                label: 'Wybierz oddział/oddziały',
                _value: department,
                _setValue: setDepartment,
                checkbox: departments?.map((d) => ({ value: d.id, label: d.name })),
            },
        ]

        const stepsSimple = [
            {
                label: 'Sposób płatności',
                _value: payment,
                _setValue: setPayment,
                radio: [
                    { value: 'cash', label: 'Gotówka' },
                    { value: 'transfer', label: 'Przelew' },
                ],
            },
            {
                label: 'Wybierz oddział',
                _value: department,
                _setValue: setDepartment,
                radio: departments?.map((d) => ({ value: d.id, label: d.name })),
            },
        ]

        const handleNext = () => {
            setActiveStep(activeStep + 1)
        }

        const handleBack = () => {
            setActiveStep(activeStep - 1)
        }

        return (
            <Stepper activeStep={activeStep} alternativeLabel orientation="horizontal">
                {departments &&
                    (orderType == 3 ? stepsSimple : steps).map(({ label, radio, checkbox, _value, _setValue }, index) =>
                        orderType != 1 && index == 0 && orderType != 3 ? null : (
                            <Step key={index}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent sx={{ border: 0 }} TransitionProps={{ unmountOnExit: false }}>
                                    <Box sx={{ mb: 2 }}>
                                        <Box>
                                            {radio && (
                                                <RadioGroup value={_value} onChange={(e) => _setValue(e.target.value)}>
                                                    {radio.map(({ value, label }) => (
                                                        <FormControlLabel value={value} label={label} control={<Radio />} />
                                                    ))}
                                                </RadioGroup>
                                            )}
                                            {checkbox && (
                                                <FormGroup>
                                                    {checkbox.map(({ value, label }) => (
                                                        <FormControlLabel
                                                            label={label}
                                                            control={
                                                                <Checkbox
                                                                    name={value}
                                                                    checked={_value?.[value]}
                                                                    onChange={(e) =>
                                                                        _setValue({
                                                                            ..._value,
                                                                            [e.target.name]: e.target.checked,
                                                                        })
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    ))}
                                                </FormGroup>
                                            )}
                                        </Box>
                                        <Box
                                            sx={Object.assign(
                                                { mt: 2 },
                                                orderType <= 2 && index === (orderType == 3 ? stepsSimple.length : steps.length) - 1
                                                    ? btnContainer
                                                    : {}
                                            )}
                                        >
                                            <Button disabled={index === 0} onClick={() => handleBack()} sx={{ mr: 1 }}>
                                                Wróć
                                            </Button>
                                            {index === (orderType == 3 ? stepsSimple.length : steps.length) - 1 ? (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => {
                                                        let _payment = [1, 2].includes(orderType) ? 'transfer' : payment
                                                        callback({
                                                            invoice,
                                                            payment: _payment,
                                                            department,
                                                            orderType,
                                                            delivery,
                                                            privateDps: privateDps ? JSON.parse(privateDps) : null,
                                                        })
                                                    }}
                                                >
                                                    Przejdź do składania zamówienia
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    onClick={() => handleNext()}
                                                    disabled={orderType == 3 ? !stepsSimple[index]._value : !steps[index]._value}
                                                >
                                                    Dalej
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </StepContent>
                            </Step>
                        )
                    )}
            </Stepper>
        )
    }

    const callbackAfterFinish = (values) => {
        setValue(values)
    }

    return (
        <Box>
            <Box className="d-flex justify-content-around">
                <OrderType type={1} title="Zamówienie z dofinansowaniem NFZ" icon={faHandHoldingDollar} />
                <OrderType type={2} title="Zamówienie bez dofinansowania" icon={faWallet} />
                <OrderType type={3} title="Zamówienie bez dofinansowania na oddział" icon={faBuilding} />
                <Card sx={{ width: 400, minHeight: 100, color: '#e18a3d', background: '#ffffff' }}>
                    <CardActionArea sx={{ height: '100%' }} href="/#/departments/templates">
                        <CardContent
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-around',
                                height: '100%',
                            }}
                        >
                            <FontAwesomeIcon icon={faListCheck} size="3x" />
                            <Typography variant="h6" textAlign="center" display="flex" flexDirection="column">
                                Zamów z szablonu
                                <hr
                                    style={{
                                        width: '40%',
                                        alignSelf: 'center',
                                        opacity: 1,
                                        height: '5px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        background: '#e18a3d',
                                    }}
                                />
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Box>
            <Box sx={{ marginTop: '60px' }}>
                <StepperOrder callback={callbackAfterFinish} />
            </Box>
        </Box>
    )
}
