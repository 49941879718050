import React, { useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReferenceInput, AutocompleteInput, Form } from 'react-admin'
import Button from '@mui/material/Button'
import authProvider from '../../config/authProvider'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize'
import { styled } from '@mui/system'
import { LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

const boxWithButtons = {
    padding: '25px',
    margin: '5px',
    spaceBeetwenn: '5px',
}
const dataView = {
    marginBottom: '20px'
}

const InputsRow = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    marginLeft: '40px',
}
const inputButton = {
    marginBottom: '20px',
    marginLeft: '40px',
}
const mainHeader = {
    margin: '25px',
}

const CustomTable = (props) =>{    
    let data = JSON.parse(props.data)
    if(data.length == 0) return <p>Brak danych</p>
    
    let keys;
    const isArray = Array.isArray(data)
    if(isArray){
        keys = Object.keys(data[0])
    } else{
        keys = Object.keys(data)
    }
    return <TableContainer>
    <Table sx={{maxWidth:1200}}>
        <TableHead>
            <TableRow style={{backgroundColor:'#f2f2f2'}}>
                {keys.map((row)=><TableCell>{row}</TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody>
            {isArray ? data.map((row)=> <TableRow> 
                {Object.values(row).map((value)=> <TableCell> {typeof value == 'object'? JSON.stringify(value) : value} </TableCell> )} 
                </TableRow>) : 
                <TableRow>
                {Object.values(data).map((row)=><TableCell>{row}</TableCell>)}
                </TableRow>}
        </TableBody>
    </Table>
    </TableContainer>

}

export default (props) => {
    const [orderData, setOrderData] = useState(null) //orders for pickup points
    const [invoiceData, setInvoiceData] = useState(null) // invoice for order id
    const [payerData, setPayerData] = useState(null) //payerBalance

    const [invoiceResponse, setInvoiceResponse] = useState()
    const [ordersResponse, setOrdersResponse] = useState()
    const [payerResponse, setPayerResponse] = useState()

    const [tmpPayerName, setTmpPayerName] = useState()
    const [payerName, setPayerName] = useState()

    const [tmpPpName, setTmpPpName] = useState()
    const [ppName, setPpName] = useState()

    const [payerLoading, setPayerLoading] = useState(false)
    const [orderLoading, setOrderLoading] = useState(false)
    const [pickupPointLoading, setPickupPointLoading] = useState(false)

    const Textarea = styled(BaseTextareaAutosize)(
        ({ theme }) => `
        box-sizing: border-box;
        width: 2000px;
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: #1f2021;
        background:   #F9F9F9;
        border: 1px solid  #d4731c;
        box-shadow: 0px 2px 2px  #ccc;
      `
    )

    const handleOrderChange = (event, newValue) => {
        let orderId = newValue?.id?.split('/').pop()
        setInvoiceData(orderId)
    }

    const handlePayerChange = (event, newValue) => {        
        let payerId = newValue?.id?.split('/').pop()
        setPayerData(payerId)
        setTmpPayerName(`[${newValue?.partnerName}] ${newValue?.name}`)
    }

    const handlePickupPointChange = (event, newValue) => {
        let pickupPointId = newValue?.id?.split('/').pop()
        setOrderData(pickupPointId)
        setTmpPpName(`[${newValue?.partnerName}] ${newValue?.internalName}`)
    }

    const getInvoicesForOrder = async (orderId, integration = 'oracle_tzmo') => {
        try {
            if(orderId){
                setOrderLoading(true)
            const requestResult = await authProvider.invoiceForOrder(orderId, integration)
            const response = JSON.stringify(requestResult)
            setInvoiceResponse(response)
            setOrderLoading(false) 
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const getOrdersForPickupPoint = async (pickupPointId, integration = 'oracle_tzmo') => {
        try {
            if(pickupPointId){
                setPickupPointLoading(true)
                const requestResult = await authProvider.ordersForPickupPoints(pickupPointId, integration)
                const response = JSON.stringify(requestResult)
                setOrdersResponse(response) 
                setPickupPointLoading(false)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }
    const getPayerTest = async (payerId, integration) => {
        try {
            if(payerId){
                setPayerLoading(true)
                const requestResult = await authProvider.singlePayerBalanceTest(payerId, integration)
                const response = JSON.stringify(requestResult)
                setPayerResponse(response)
                setPayerLoading(false)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    const handleButtonClick = () => {
        const integration = 'oracle_tzmo'
        getPayerTest(payerData, integration)
        setPayerName(tmpPayerName ?? payerName)
    }

    const handleButtonClick2 = () => {
        getInvoicesForOrder(invoiceData)
    }
    const handleButtonClick3 = () => {
        getOrdersForPickupPoint(orderData)
        setPpName( tmpPpName?? ppName)
    }

    return (
        <Card>
            <CardContent>
                <Form>
                    <h1 style={mainHeader}>Oracle</h1>
                    <div style={InputsRow}>
                        <ReferenceInput source="payer" resource="payers" reference="payers" style={inputButton} filter={{'exists[payerNetwork]': true}}>
                            <AutocompleteInput
                                label="Platnik"
                                variant="outlined"
                                resettable
                                optionText={(payer) => `[${payer.partnerName}] ${payer.name}`}
                                filterToQuery={(searchText)=>({
                                    name: searchText
                                })}
                                onChange={handlePayerChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>

                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick}>
                            Pokaż bilans
                        </Button>
                    </div>

                    <div style={InputsRow}>
                        <ReferenceInput source="nrZam" resource="orders" reference="orders">
                            <AutocompleteInput
                                label="Zamówienie (Numer zamówienia Oracle)"
                                variant="outlined"
                                resettable
                                optionText={(order) => `${order.nrZam}`}
                                filterToQuery={(searchText)=>({
                                    nrZam: searchText
                                })}
                                onChange={handleOrderChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>
                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick2}>
                            Pokaż faktury
                        </Button>
                    </div>

                    <div style={InputsRow}>
                        <ReferenceInput source="pickupPoint" resource="pickup_points" reference="pickup_points">
                            <AutocompleteInput
                                label="Punkt odbioru"
                                variant="outlined"
                                resettable
                                optionText={(pp) => `[${pp.partnerName}] ${pp.internalName}`}
                                filterToQuery={(searchText)=>({
                                    internalName: searchText
                                })}
                                onChange={handlePickupPointChange}
                                sx={{ width: '450px' }}
                            />
                        </ReferenceInput>
                        <Button variant="outlined" style={inputButton} onClick={handleButtonClick3}>
                            Pokaż zamówienia
                        </Button>
                    </div>

                    <div style={boxWithButtons}>
                        <div style={{ border: '5px' }}>
                            {payerResponse && (
                                <div style={dataView}>
                                    <h3>Bilans</h3>
                                    <p>{payerName}</p>
                                    {!payerLoading ?  <CustomTable data = {payerResponse}/> : <LinearProgress sx={{maxWidth:1200}}/>}
                                </div>
                            )}
                            {invoiceResponse && (
                                <div style={dataView}>
                                    <h3>Faktura:</h3>
                                    {!orderLoading ? <CustomTable data={invoiceResponse}/> : <LinearProgress sx={{maxWidth:1200}}/>}
                                </div>
                            )}
                            {ordersResponse && (
                                <div style={dataView}>
                                    <h3>Zamówienia:</h3>
                                    <p>{ppName}</p>
                                    {!pickupPointLoading ? <CustomTable data={ordersResponse}/> : <LinearProgress sx={{maxWidth:1200}}/>}
                                </div>
                            )}
                        </div>
                    </div>
                </Form>
            </CardContent>
        </Card>
    )
}