import React from 'react'
import { Create, SimpleForm, TextInput, required, BooleanInput, ReferenceInput, AutocompleteInput } from 'react-admin'
import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required()

export default (props) => {
    return (
        <Create {...props} title={`Dodaj zgodę`}>
            <SimpleForm {...props} toolbar={<PostBottomToolbarAdd />}>
                <div className="d-flex w-100 justify-content-around">
                    <div class="col-sm-5">
                        <TextInput source="name" label="Nazwa" variant="outlined" fullWidth validate={requiredField} />
                        <TextInput source="content" label="Treść" variant="outlined" rows={4} multiline fullWidth validate={requiredField} />
                        <span>Zostaw to pole puste jeśli tworzysz zgodę dla wszystkich partnerów</span>
                        <ReferenceInput source="partner" reference="partners" fullWidth>
                            <AutocompleteInput
                                variant="outlined"
                                label="Partner"
                                optionText={'name'}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                emptyText="Wszystkie"
                            />
                        </ReferenceInput>
                        <BooleanInput source="status" label="Status" variant="outlined" />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
