import MuiButton from '@mui/material/Button'
import decodeJwt from 'jwt-decode'
import md5 from 'md5'
import * as React from 'react'
import {
    BooleanField,
    Button,
    DateField,
    FunctionField,
    NumberField,
    ReferenceField,
    ReferenceManyField,
    ReferenceArrayField,
    Show,
    SimpleShowLayout,
    TextField,
    useListContext,
    usePermissions,
    useRecordContext,
    SingleFieldList,
    ChipField,
    WithRecord,
    Link,
} from 'react-admin'
import PermissionHandler from '../../config/PermissionHandler'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import { CustomTitle } from '../CustomElements/CustomTitle'
import { CustomPagination } from '../CustomElements/CustomPagination'
import { DataGrid, GridColDef, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, plPL } from '@mui/x-data-grid'
import { Box, Typography, Divider} from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export default (props, source) => {
    const { isLoading, permissions } = usePermissions()
    const [patientsView, setPatintsView] = React.useState(false)

    const CustomAddToCartButton = (props) => {
        const record = useRecordContext()
        let token = localStorage.getItem('token')
        let decodedToken = decodeJwt(token)
        let username = decodedToken.username
        let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)
        let prestaUrl = localStorage.getItem('shopUrl')

        return (
            <Button
                variant="outlined"
                color="success"
                label={'Dodaj do koszyka'}
                onClick={() => {
                    let url =
                        prestaUrl +
                        '/superlogin.php?hash=' +
                        hashMd5 +
                        '&email=' +
                        username +
                        '&token=' +
                        token +
                        '&timestamp=' +
                        Date.now() +
                        '&redirect=true&product_sku=' +
                        record.sku +
                        '|' +
                        record.quantity
                    window.open(url, '_blank').focus()
                }}
            />
        )
    }

    const DatagridWrapper = (props) => {
        const record = useRecordContext()
        const { data, isLoading } = useListContext()

        return (
            <CustomDataGrid
                isAddToCartButton={true}
                permissions={permissions}
                isFooterOrderOne={true}
                footerOrderOneData={{
                    allNet: record?.net,
                    allGross: record?.gross,
                }}
            >
                <TextField source="bloz7" label="BLOZ7" emptyText="-" />
                <TextField source="ean" label="EAN" emptyText="-" />
                <TextField source="name" label="Nazwa" emptyText="-" />
                <NumberField
                    source="pricePerUnitNet"
                    label="Cena jednostkowa netto"
                    options={{
                        style: 'currency',
                        currency: 'PLN',
                        maximumFractionDigits: 2,
                    }}
                />
                <NumberField
                    source="pricePerUnitGross"
                    label="Cena jednostkowa brutto"
                    options={{
                        style: 'currency',
                        currency: 'PLN',
                        maximumFractionDigits: 2,
                    }}
                />
                <TextField source="quantity" label="Ilość sztuk" />
                <FunctionField
                    label="Wartość netto"
                    render={(record) => `${(record.pricePerUnitNet * record.quantity).toFixed(2)} zł`.replace('.', ',')}
                />
                <FunctionField
                    label="Wartość brutto"
                    render={(record) => `${(record.pricePerUnitGross * record.quantity).toFixed(2)} zł`.replace('.', ',')}
                />

                {PermissionHandler(permissions, 'Dashboard', 'CanBuy') && (
                    <CustomAddToCartButton
                        {...props}
                        isAddToCart={true}
                        {...props}
                        label={
                            <MuiButton
                                variant="outlined"
                                color="secondary"
                                onClick={() => {
                                    let token = localStorage.getItem('token')
                                    let decodedToken = decodeJwt(token)
                                    let username = decodedToken.username
                                    let hashMd5 = md5(username + process.env.REACT_APP_MD5_HASH)
                                    let prestaUrl = localStorage.getItem('shopUrl')

                                    let skus = data.map((el) => el.sku + '|' + el.quantity).join(',')
                                    let url =
                                        prestaUrl +
                                        '/superlogin.php?hash=' +
                                        hashMd5 +
                                        '&email=' +
                                        username +
                                        '&token=' +
                                        token +
                                        '&timestamp=' +
                                        Date.now() +
                                        '&redirect=true&product_sku=' +
                                        skus

                                    window.open(url, '_blank').focus()
                                }}
                            >
                                Dodaj wszystkie pozycje
                                <br />
                                do koszyka
                            </MuiButton>
                        }
                    />
                )}
            </CustomDataGrid>
        )
    }

    const DatagridWrapperDps = (props) => {
        const record = useRecordContext()

        return (
            <CustomDataGrid
                isAddToCartButton={true}
                permissions={permissions}
                isFooterOrderOne={true}
                footerOrderOneData={{
                    allGross: record?.gross,
                }}
            >
                <TextField source="sku" label="SKU" emptyText="-" />
                <NumberField
                    source="price"
                    label="Cena"
                    options={{
                        style: 'currency',
                        currency: 'PLN',
                        maximumFractionDigits: 2,
                    }}
                />
                <TextField source="quantity" label="Ilość opakowań" />
                <FunctionField label="Wartość brutto" render={(record) => `${(record.price * record.quantity).toFixed(2)} zł`.replace('.', ',')} />
            </CustomDataGrid>
        )
    }

    const OrderProductsGrid = () => {
        const record = useRecordContext()
        return (
            <ReferenceManyField reference={record?.dps ? 'product_order_dps' : 'product_orders'} target={record?.dps ? 'mainOrder' : 'order'}>
                {record?.dps ? <DatagridWrapperDps /> : <DatagridWrapper />}
                <CustomPagination />
            </ReferenceManyField>
        )
    }

    const DatagridWrapperDpsPatients = (props) => {
        const Expand = () => {
            const record = useRecordContext()

            const currencyFormatter = new Intl.NumberFormat('pl-PL', {
                style: 'currency',
                currency: 'PLN',
            })
            const columns: GridColDef[] = [
                { field: 'sku', headerName: 'SKU', flex: 1 },
                { field: 'price', headerName: 'Cena', flex: 1, valueFormatter: ({ value }) => currencyFormatter.format(value) },
                { field: 'quantity', headerName: 'Ilość sztuk', flex: 1 },
                {
                    field: 'priceTotal',
                    headerName: 'Wartość brutto',
                    flex: 1,
                    valueGetter: (params) => {
                        return params.row?.price * params.row?.quantity
                    },
                    flex: 0.5,
                    valueFormatter: ({ value }) => currencyFormatter.format(value),
                },
            ]

            return (
                <Box>
                    <Box
                        style={{
                            width: '100%',
                            minHeight: '275px',
                            display: 'grid',
                        }}
                    >
                        <DataGrid
                            rows={record.productsOrderDps}
                            columns={columns}
                            getRowId={(row) => row?.id}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: 'rgba(0,0,0,.07)',
                                },
                                '& .odd': {
                                    backgroundColor: 'rgba(0,0,0,.02)',
                                },
                            }}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 == 0 ? 'even' : 'odd')}
                            slots={{
                                toolbar: () => (
                                    <Box>
                                        <GridToolbarContainer>
                                            <Box display="flex" justifyContent="space-between" width="100%" marginX="10px">
                                                <Typography variant="h5" marginY={1.5}>
                                                    Linie zamówienia
                                                </Typography>
                                                <Box>
                                                    <GridToolbarDensitySelector />
                                                    <GridToolbarColumnsButton />
                                                </Box>
                                            </Box>
                                        </GridToolbarContainer>
                                        <Divider sx={{ opacity: 1 }} />
                                    </Box>
                                ),
                            }}
                            localeText={plPL.components.MuiDataGrid.defaultProps.localeText}
                            hideFooter={true}
                        />
                    </Box>
                </Box>
            )
        }

        return (
            <CustomDataGrid expand={<Expand />}>
                <TextField source="patient.name" label="Imię" />
                <TextField source="patient.surname" label="Nazwisko" />
                <ReferenceField source="patient.department" reference="departments" label="Oddział">
                    <TextField source="name" label="Nazwa" />
                </ReferenceField>
                <TextField source="refundDocumentRealization.refundNumber" emptyText='-' label="Numer zlecenia refundacyjnego" />
                <TextField source="refundDocumentRealization.monthCount" emptyText='-' label="Ilość zrealizowanych miesięcy" />
                <FunctionField label="Wartość dofinansowania NFZ" render={(record) => `${record.refValue.toFixed(2)} zł`.replace('.', ',')} />
                <FunctionField label="Wartość dopłaty DPS" render={(record) => `${record.institutionPayment.toFixed(2)} zł`.replace('.', ',')} />
                <FunctionField label="Opłata przelewem" render={(record) => `${record.patTransferPayment.toFixed(2)} zł`.replace('.', ',')} />
                <FunctionField label="Opłata gotówką" render={(record) => `${record.patCashPayment.toFixed(2)} zł`.replace('.', ',')} />
            </CustomDataGrid>
        )
    }

    const OrderProductsPatientsView = () => {
        return (
            <ReferenceManyField reference="patient_orders" target="mainOrder">
                <DatagridWrapperDpsPatients />
                <CustomPagination />
            </ReferenceManyField>
        )
    }

    return (
        <Show {...props} title={<CustomTitle resourceName="Zamówienie" fields={['orderId']} />}>
            <SimpleShowLayout>
                <TextField source="nrZam" label="Numer zamówienia" />
                <DateField source="date" label="Data wystawienia" showTime={true} />
                <ReferenceField source="pickupPoint" reference="pickup_points" link={false} label="Punkt odbioru">
                    <TextField source="internalName" />
                </ReferenceField>
                {PermissionHandler(permissions, 'Orders', 'DpsView') && (
                    <ReferenceArrayField reference="departments" source="departments" label="Oddziały">
                        <SingleFieldList linkType={false} empty="Brak">
                            <ChipField source="name" />
                        </SingleFieldList>
                    </ReferenceArrayField>
                )}
                <TextField source="status" label="Status realizacji" />
                <BooleanField source="paymentStatus" label="Status płatności" valueLabelTrue="Opłacone" valueLabelFalse="Nieopłacone" />
                <TextField source="zrzam" label="Źródło" />
                {PermissionHandler(permissions, 'Orders', 'TrackLink') && <FunctionField
                    source="Śledzenia zamówienia"
                    render={(record) =>
                        record.danewysylki ? (
                            <ul>
                                {record.danewysylki.map((deliver) => (
                                    <>
                                        {' '}
                                        <Link className="d-block" target="_blank" underline="hover" to={deliver.linkSledzenia}>
                                            {' '}
                                            {deliver.linkSledzenia}
                                        </Link>
                                    </>
                                ))}
                            </ul>
                        ) : (
                            '-'
                        )
                    }
                />}
                {PermissionHandler(permissions, 'Orders', 'DpsView') && <TextField source="dpsPaymentType" emptyText="-" label="Typ płatności" />}
                {PermissionHandler(permissions, 'Orders', 'DpsView') && <TextField source="dpsPayerType" emptyText="-" label="Sposób fakturowania" />}
                {PermissionHandler(permissions, 'Orders', 'DpsView') && <TextField source="dpsDeliveryType" emptyText="-" label="Sposób odebrania" />}
                {PermissionHandler(permissions, 'Orders', 'DpsView') && <BooleanField source="privateDps" label="Dofinansowanie DPS" emptyText='-' FalseIcon={DoneIcon} TrueIcon={CloseIcon} valueLabelTrue="Nie" valueLabelFalse="Tak" />}

                {patientsView ? <OrderProductsPatientsView label="Podział na podopiecznych" /> : <OrderProductsGrid label="Pozycje zamówienia" />}
                <WithRecord
                    render={(record) =>
                        record?.dps ? (
                            <MuiButton onClick={() => setPatintsView(!patientsView)} variant="contained">
                                {patientsView ? 'Domyślna lista' : 'Podział na podopiecznych'}
                            </MuiButton>
                        ) : null
                    }
                />
            </SimpleShowLayout>
        </Show>
    )
}
