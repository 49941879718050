import * as React from 'react'
import {
    AutocompleteInput,
    BooleanInput,
    Create,
    email,
    ReferenceInput,
    required,
    SimpleForm,
    TextInput,
    usePermissions,
} from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')
const isEmail = [
    required('Pole wymagane'),
    email('Podany jest nie prawidłowy e-mail'),
]

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create {...props} actions={null} title={`Dodaj płatnika użytkownika`}>
            <SimpleForm toolbar={<PostBottomToolbarAdd />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput
                            variant="outlined"
                            source="name"
                            label="Imię"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            label="Nazwisko"
                            variant="outlined"
                            source="surname"
                            validate={requiredField}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            label="E-mail"
                            source="email"
                            validate={isEmail}
                            fullWidth
                        />

                        <TextInput
                            variant="outlined"
                            source="phone"
                            label="Numer telefonu"
                            fullWidth
                        />

                        <ReferenceInput
                            source="payerNetwork"
                            reference="payer_networks"
                            validate={requiredField}
                            fullWidth
                        >
                            <AutocompleteInput
                                variant="outlined"
                                optionText={'name'}
                                validate={requiredField}
                                filterToQuery={(searchText) => ({
                                    name: searchText,
                                })}
                                label="Płatnik sieciowy"
                            />
                        </ReferenceInput>

                        <BooleanInput
                            variant="outlined"
                            source="status"
                            label="Status"
                            fullWidth
                        />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
