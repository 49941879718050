import * as React from 'react'
import { Filter, List, TextField, FunctionField, TextInput, usePermissions, downloadCSV, fetchRelatedRecords } from 'react-admin'
import jsonExport from 'jsonexport/dist'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomDataGrid from '../CustomElements/CustomDataGrid'
import CustomActionBar from '../CustomElements/CustomActionBar'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'

import { CustomPagination } from '../CustomElements/CustomPagination'

export default (props) => {
    const { isLoading, permissions } = usePermissions()

    const exporter = async (payer_networks, fetchRelatedRecords) => {
        const _payer_discounts_formatted = payer_networks?.map((pn) =>
            pn?.discounts?.map((payer_discount) => 
                ({
                'Płatnik sieciowy': `${pn.name} (${pn.id?.split("/")?.[2]})`,
                Marka: payer_discount.category,
                'Wartość [%]': payer_discount.value,
            }))
        )

        return jsonExport(
            _payer_discounts_formatted ?? [],
            {
                headers: ['Płatnik sieciowy','Marka', 'Wartość [%]'],
            },
            (err, csv) => {
                downloadCSV(csv, 'warunki_handlowe')
            }
        )
    }

    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, 'PayerNetworks', 'Create')} exportButton={PermissionHandler(permissions, 'PayerNetworks', 'ExportAllDiscounts')} />}
            bulkActionButtons={false}
            title={`Płatnicy sieciowi`}
            empty={<EmptyList title="płatników sieciowych" add={PermissionHandler(permissions, 'PayerNetworks', 'Create')} />}
            filters={[
                <TextInput alwaysOn variant="outlined" resettable label="Nazwa" source="name" />,

                <TextInput alwaysOn variant="outlined" resettable label="Partner" source="partner.name" />,
            ]}
            exporter={exporter}
        >
            <CustomDataGrid>
                <FunctionField label="ID" source="id" render={(record) => `${record.id.split('/').pop()}`} />
                ;
                <TextField
                    source="name"
                    label="Nazwa"
                    //sortable={false}
                />
                <TextField
                    source="partnerName"
                    label="Partner"
                    //sortable={false}
                />
                {PermissionHandler(permissions, 'PayerNetworks', 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDescriptionAvailable={PermissionHandler(permissions, 'PayerNetworks', 'Read')}
                        isDeleteAvailable={false}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
