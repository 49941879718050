import React from 'react'

import { Create, SimpleForm, TextInput, required, SelectInput, SelectArrayInput, ReferenceArrayInput, RadioButtonGroupInput, FormDataConsumer } from 'react-admin';
import { PostBottomToolbarEdit } from '../CustomElements/PostBottomToolbars';
import TinyMCEInput from '../CustomInputs/TinyMCEInput';

import './style.css'
import { faBandage, faCalculator, faCommentDollar, faEye, faFile, faFileContract, faHeart, faHospital, faInfoCircle, faPhone, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const iconChoices = [
  {id: 'faFileContract', icon: faFileContract},
  {id: 'faCalculator', icon: faCalculator},
  {id: 'faCommentDollar', icon: faCommentDollar},
  {id: 'faInfoCircle', icon: faInfoCircle},
  {id: 'faPhone', icon: faPhone},
  {id: 'faFile', icon: faFile},
  {id: 'faHeart', icon: faHeart},
  {id: 'faEye', icon: faEye},
  {id: 'faHospital', icon: faHospital },
  {id: 'faBandage', icon: faBandage}
]
export const rolesChoices = [
  { id: 'ROLE_ADMIN', name: 'Admin'},
  { id: 'ROLE_PARTNER', name: 'Partner'},
  { id: 'ROLE_PAYER_NETWORK', name: 'Płatnik sieciowy'},
  { id: 'ROLE_PAYER', name: 'Płatnik'},
  { id: 'ROLE_MANAGER', name: 'Kierownik'},
  { id: 'ROLE_WORKER', name: 'Pracownik'},
  { id: 'ROLE_USER', name: 'Wszyscy'},
]

export default (props) => {
  const requiredField = required('Pole wymagane')

  const optionRenderer = choice => <FontAwesomeIcon icon={choice.icon} />
  
  return <Create resource='refund_menus' title={' '}>
      <SimpleForm toolbar={<PostBottomToolbarEdit />}>
        <TextInput source='header' variant='outlined' label='Tytuł' required={requiredField}/>
        <SelectArrayInput variant='outlined' label="Widoczność dla:" source='roles' choices={rolesChoices} defaultValue={["ROLE_ADMIN"]}/>
        <ReferenceArrayInput source='partners' reference='partners'>
          <SelectArrayInput variant='outlined' label='Partnerzy' optionText='name'/>
        </ReferenceArrayInput>
        <SelectInput source='icon' variant='outlined' optionText={optionRenderer} label="Ikona menu" required={requiredField} choices={iconChoices} />
        <RadioButtonGroupInput source='contentType' defaultValue={'content'} label="Typ kafelka" choices={[
          {id: 'content', name: 'Zawartość'},
          {id: 'link', name: 'Link'}
        ]}/>
        <FormDataConsumer>
            {({formData})=> {
              if( formData.contentType == 'content') return <div className="tiny">
              <TinyMCEInput source='body' className="tiny"/>
              </div>
              if(formData.contentType == 'link') return <TextInput source='link' label='link' variant='outlined' />
            }}
        </FormDataConsumer>
      </SimpleForm>
    </Create> 

}