import * as React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { useRedirect, useListContext, useGetOne } from 'react-admin'
import QuantityCounter from './QuantityCounter'
import { AlertTitle, Collapse, Tooltip } from '@mui/material'

const CustomCardGrid = () => {
    const { data } = useListContext()
    const { data: pointsData, isPending, error } = useGetOne('', { id: 'pk_users/get_points_data' })
    const [pointsOpen, setPointsOpen] = React.useState(false)

    const Inputs = ({ product }) => {
        const [quantity, setQuantity] = React.useState(1)
        const redirect = useRedirect()

        const formOrder = (id) => {
            redirect(`/profit_products/%2Fprofit_products%2F${id}/show?quantity=${quantity}`)
        }

        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <QuantityCounter quantity={quantity} setQuantity={setQuantity} min={1} max={product?.unlimited ? undefined : product?.quantity} />
                <Button sx={{ marginTop: '10px' }} variant="contained" color="primary" onClick={() => formOrder(product.originId)}>
                    Zamawiam
                </Button>
            </Box>
        )
    }

    return (
        <Box>
            <Alert severity="info">
                <Box>
                    <AlertTitle onClick={() => setPointsOpen(!pointsOpen)} variant="h6">
                        <Tooltip title="Kliknij żeby rozwinąć">
                            <Button color="inherit">Informacja o punktach</Button>
                        </Tooltip>
                    </AlertTitle>
                    <Collapse in={pointsOpen}>
                        {pointsData?.type == 2 ? (
                            <Typography variant="body2">Instytucja: {pointsData.institution ?? 0} pkt</Typography>
                        ) : (
                            pointsData?.departments?.map(({ name, id, points }) => (
                                <Typography variant="body2">
                                    {name} ({id}): {points ?? 0} pkt
                                </Typography>
                            ))
                        )}
                    </Collapse>
                </Box>
            </Alert>
            <Box>
                {data?.map((_product) => (
                    <Card
                        style={{
                            minWidth: 200,
                            minHeight: 200,
                            margin: '0.5em',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                    >
                        <CardContent>
                            <Typography gutterBottom variant="h5">
                                {_product.name}
                            </Typography>
                            <img
                                src={_product?.mainImg ?? '/img/reward_default.png'}
                                alt={_product.name}
                                style={{ width: '300px', height: 'auto' }}
                            />
                            <Typography variant="body1">Wartość PLN: {_product.price} zł</Typography>
                            <Typography variant="body1">Wartość PKT: {_product.points} pkt</Typography>
                        </CardContent>
                        <CardActions>
                            <Inputs product={_product} />
                        </CardActions>
                    </Card>
                ))}
            </Box>
        </Box>
    )
}

export default CustomCardGrid
