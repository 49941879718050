import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    NumberInput,
    BooleanInput,
    ArrayInput,
    SimpleFormIterator,
    SelectInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    required,
    ImageField,
} from 'react-admin'
import { ColorField, ColorInput } from 'react-admin-color-picker'
import { PostBottomToolbarEdit } from '../../CustomElements/PostBottomToolbars'
import { Box, Button, TextField, Typography } from '@mui/material'
import { RichTextInput } from 'ra-input-rich-text'
import ImagesSearch from '../CustomElements/ImagesSearch'
import ImagesList from '../CustomElements/ImagesList'
const imgSize = { width: 100, height: 100 }

export default (props) => {
    const [open, setOpen] = React.useState(false);

    return (
        <Edit {...props} actions={null} title={'Edytuj produkt'}>
            <SimpleForm toolbar={<PostBottomToolbarEdit />}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column', width: '100%', marginRight: 5 }}>
                            <TextInput variant="outlined" source="name" fullWidth label="Nazwa nagrody" />

                            <RichTextInput variant="outlined" source="description" fullWidth label="Opis" />
                        </Box>

                        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <SelectInput
                                label="Typ nagrody"
                                variant="outlined"
                                source="type"
                                resettable
                                fullWidth
                                choices={[
                                    { id: 0, name: 'Rzeczowa' },
                                    { id: 1, name: 'Edukacyjna' },
                                ]}
                                alwaysOn
                            />

                            <NumberInput variant="outlined" source="price" fullWidth label="Cena w PLN" />

                            <NumberInput variant="outlined" source="points" fullWidth label="Cena w PKT" />

                            <NumberInput variant="outlined" source="quantity" fullWidth label="Dostępna ilość" />

                            <BooleanInput variant="outlined" source="unlimited" fullWidth label="Bez limitu sztuk" />

                            <BooleanInput variant="outlined" source="active" fullWidth label="Status" />

                            <TextInput variant="outlined" source="ribbonText" fullWidth label="Tekst szarfy" />

                            <ColorInput variant="outlined" source="ribbonColor" fullWidth label="Kolor szarfy" />
                        </Box>
                    </Box>

                    <Box>
                        
                        <Box>
                            <ImagesList />
                            <Button variant='contained' onClick={()=>setOpen(true)}>Dodaj zdjęcia</Button>
                            <ImagesSearch open={open} setOpen={setOpen}/>
                        </Box>

                        <Box>
                            <ArrayInput label="Atrybuty" variant="outlined" fullWidth source="attributes">
                                <SimpleFormIterator inline>
                                    <TextInput variant="outlined" source="attributeName" label="Nazwa atrybutu" />
                                    <ArrayInput variant="outlined" source="attributeValues" label="Wartości atrybutu">
                                        <SimpleFormIterator inline>
                                            <TextInput variant="outlined" source="value" label="Wartość" />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Box>
                    </Box>
                </Box>
            </SimpleForm>
        </Edit>
    )
}
