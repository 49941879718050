import * as React from 'react'
import { AutocompleteInput, BooleanInput, Create, email, ReferenceInput, required, SimpleForm, TextInput, usePermissions } from 'react-admin'

import { PostBottomToolbarAdd } from '../CustomElements/PostBottomToolbars'

const requiredField = required('Pole wymagane')
const isEmail = [required('Pole wymagane'), email('Podany jest nie prawidłowy e-mail')]

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <Create {...props} actions={null} title={`Dodaj płatnika użytkownika`}>
            <SimpleForm toolbar={<PostBottomToolbarAdd />}>
                <div className="d-flex w-100 justify-content-around">
                    <div className="col-sm-5">
                        <TextInput label="Imię" variant="outlined" source="name" validate={requiredField} fullWidth />

                        <TextInput label="Nazwisko" variant="outlined" source="surname" validate={requiredField} fullWidth />

                        <TextInput label="E-mail" variant="outlined" source="email" validate={isEmail} fullWidth />

                        <TextInput label="Numer Telefonu" variant="outlined" source="phone" fullWidth />

                        <ReferenceInput source="payer" reference="payers" validate={requiredField} fullWidth>
                            <AutocompleteInput
                                variant="outlined"
                                label="Płatnik"
                                optionText={(record)=>record.partnerName ? `[${record.partnerName}] ${record.name}`: `${record.name}`}
                                filterToQuery={(searchText) => ({
                                    q: searchText,
                                    synchronize: true,
                                })}
                                validate={requiredField}
                            />
                        </ReferenceInput>

                        <BooleanInput variant="outlined" source="status" label="Status" fullWidth />
                    </div>
                </div>
            </SimpleForm>
        </Create>
    )
}
