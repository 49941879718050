import 'bootstrap/dist/css/bootstrap.min.css'
import $ from 'jquery'
import Popper from 'popper.js'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
//import * as serviceWorker from './serviceWorker'

import { SnackbarProvider } from 'notistack'
import { IconButton } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/pl'

import { StylesProvider, createGenerateClassName } from '@mui/styles'

import { ConfirmProvider } from 'material-ui-confirm'
import "react-image-gallery/styles/css/image-gallery.css";

const notistackRef = React.createRef()
const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
}

const generateClassName = createGenerateClassName({
    seed: 'App1',
})

ReactDOM.render(
    <React.StrictMode>
        <StylesProvider generateClassName={generateClassName}>
            <ConfirmProvider>
                <SnackbarProvider
                    ref={notistackRef}
                    action={(key) => (
                        <IconButton
                            variant="light"
                            onClick={onClickDismiss(key)}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    )}
                    maxSnack={10}
                >
                    <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale="pl"
                    >
                        <App />
                    </LocalizationProvider>
                </SnackbarProvider>
            </ConfirmProvider>
        </StylesProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister()
