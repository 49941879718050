import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ListButton, Title, useLogout, usePermissions, useGetOne, useRefresh, useGetList } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faUserCircle, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import authProvider from '../../config/authProvider'

import { useSnackbar } from 'notistack'

import PermissionHandler from '../../config/PermissionHandler'
import ConsentCheckedList from '../Consent/ConsentCheckedList'
import RegulationCheckedList from '../Regulations/RegulationCheckedList'

export default function (props) {
    const { permissions } = usePermissions()
    const logout = useLogout()
    const refresh = useRefresh()

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [passwdError, setPasswdError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [showPasswd, setShowPasswd] = useState('')

    const [show, setShow] = useState(false)
    const [isFetched, setIsFetched] = useState(false)
    const [inputs, setInputs] = useState({
        email: null,
        firstName: null,
        id: null,
        lastName: null,
        phone: null,
        payer: [],
    })

    if (localStorage.getItem('reload') == 'true') {
        localStorage.setItem('reload', false)

        window.location.reload()
        return false
    }

    const onChangeForField = ({ target }) => {
        setInputs({ ...inputs, [target.name]: target.value })
    }

    useEffect(() => {
        if (data != undefined) {
            let payerInfo = data.payer ? JSON.parse(data.payer) : null
            setInputs({
                ...inputs,
                id: data.id,
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                payer: payerInfo,
                payerNetworkName: data?.payerNetworkName,
            })
        }
    }, [isFetched])

    /////// pk_users/me fetching
    const { data, loading, error } = useGetOne('pk_users', {
        id: `pk_users/me`,
    })
    if (data != undefined && isFetched == false) {
        setIsFetched(true)
    }

    /////// pk_users/me fetching end

    const {
        data: dataPD,
        loading: loadingPD,
        error: errorPD,
    } = useGetList('payer_discounts', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'DESC' },
    })

    const {
        data: dataParD,
        loading: loadingParD,
        error: errorParD,
    } = useGetList('partner_const_discounts', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'DESC' },
    })

    const showModal = () => {
        setShow(true)
    }

    const hideModal = () => {
        setShow(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        let consents = Array.from(document.querySelectorAll('input[name="consent[]"][type="checkbox"]')).map((x) => [x.value, x.checked])

        if (consents?.length > 0) {
            consents = JSON.stringify(consents)
            authProvider.saveConsents({ consents }).then((response) => {
                enqueueSnackbar(response.message, {
                    variant: 'info',
                    autoHideDuration: 10000,
                })
            })
        }

        if (!data?.isRegulationChecked) {
            let regulations = Array.from(document.querySelectorAll('input[name="regulation[]"][type="checkbox"]')).map((x) => [x.value, x.checked])
            regulations = JSON.stringify(regulations)

            authProvider.saveRegulations({ regulations }).then((response) => {
                enqueueSnackbar(response.message, {
                    variant: 'info',
                    autoHideDuration: 10000,
                })
            })
        }

        if (inputs.phone != data?.phone) {
            const phone = inputs.phone

            authProvider.changePhone({ phone }).then(
                (response) => {
                    enqueueSnackbar(response.message, {
                        variant: 'info',
                        autoHideDuration: 10000,
                    })
                },
                (error) => {
                    const resMessage = (error.response && error.response.message) || error.message || error.toString()
                    enqueueSnackbar(resMessage, {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    })
                }
            )
        }

        if (inputs.password == inputs.password2 && inputs.password && inputs.password.length > 3) {
            setPasswdError('')
            const password = inputs.password
            const password2 = inputs.password2
            authProvider.changePassword({ password, password2 }).then(
                (response) => {
                    enqueueSnackbar(response.message, {
                        variant: 'info',
                        autoHideDuration: 10000,
                    })
                    logout().then(() => {
                        enqueueSnackbar('Proszę zalogować się ponownie', {
                            variant: 'warning',
                            autoHideDuration: 10000,
                        })
                    })
                },
                (error) => {
                    const resMessage = (error.response && error.response.message) || error.message || error.toString()
                    enqueueSnackbar(resMessage, {
                        variant: 'warning',
                        autoHideDuration: 10000,
                    })
                }
            )
        } else if (inputs.password !== inputs.password2) {
            setPasswdError('Hasła różnią się')
        } else if (inputs.password && inputs.password.length < 3) {
            setPasswdError('Długość hasła jest zbyt mała')
        } else if (!inputs.password && !inputs.password2) {
            setPasswdError('')
        }
    }

    const discontCategory = (slug) => {
        switch (slug) {
            case 'seni-quarter':
                return 'Seni kwartalny'
            case 'seni-edu':
                return 'Seni edukacyjny'
            case 'seni':
                return 'Seni'
            case 'bella':
                return 'Bella'
            case 'matopat':
                return 'Matopat'
            case 'pollena-eva':
                return 'Pollena Eva'
            case 'matopat-plastry-konsumenckie-cat-30':
                return 'Matopat - Plastry Konsumenckie'
            case 'matopat-plastry-szpitalne-i-przylepce-cat-60':
                return 'Matopat - Plastry szpitalne i przylepce'
            case 'other':
                return 'Obce'
        }
    }

    return (
        <Card>
            <Title title="Moje dane" />
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <div className="d-flex w-100 justify-content-around">
                        {PermissionHandler(permissions, 'MyData', 'PayerDataFV') && (
                            /* col1 */
                            <div className="col-xs-6">
                                <h1 className="text-center">
                                    <span className="badge bg-danger">
                                        <FontAwesomeIcon icon={faHome} />
                                        &nbsp; Dane płatnika - FV
                                    </span>
                                </h1>
                                <div className="table">
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th className="col-form-label">Nazwa</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.name}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">NIP</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.nip}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Miejscowość</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.city}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Kod pocztowy</th>
                                                <td className="col-md">{<span>{inputs.payer && inputs.payer.postCode}</span>}</td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Ulica</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.street}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Województwo</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.voivodeship}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Numer klienta</th>
                                                <td className="col-md">
                                                    <span>{inputs.payer && inputs.payer.clientNumber}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="col-form-label">Kontrahent</th>
                                                <td className="col-md">
                                                    <span>{inputs.payerNetworkName}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}

                        {/* col2 */}
                        <div className="col-xs-6">
                            <h1 className="text-center">
                                <span className="badge bg-primary">
                                    <FontAwesomeIcon icon={faUserCircle} />
                                    &nbsp; Dane kontaktowe
                                </span>
                            </h1>
                            <div className="table">
                                <table className="table table-striped">
                                    <tbody>
                                        <tr>
                                            <th className="col-form-label">Imię</th>
                                            <td className="col-md">
                                                <span>{inputs.firstName}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Nazwisko</th>
                                            <td className="col-md">
                                                <span>{inputs.lastName}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Adres e-mail</th>
                                            <td className="col-md">
                                                <span>{inputs.email}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Płatnik</th>
                                            <td className="col-md">
                                                <span>{inputs?.payer?.name ?? '-'}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">NIP</th>
                                            <td className="col-md">
                                                <span>{inputs?.payer?.nip ?? '-'}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Kontrahent</th>
                                            <td className="col-md">
                                                <span>{inputs.payerNetworkName || '-'}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Numer tel.</th>
                                            <td className="col-md">
                                                <input
                                                    name="phone"
                                                    value={inputs.phone}
                                                    className={phoneError == '' ? 'form-control' : 'form-control is-invalid'}
                                                    onChange={onChangeForField}
                                                />
                                                {phoneError != '' && <span className="text-danger">{phoneError}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Nowe hasło</th>
                                            <td className="col-md">
                                                <input
                                                    name={'password'}
                                                    type={showPasswd ? 'text' : 'password'}
                                                    className={passwdError == '' ? 'form-control' : 'form-control is-invalid'}
                                                    onChange={onChangeForField}
                                                    required={inputs.password2}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="col-form-label">Powtórz hasło</th>
                                            <td className="col-md">
                                                <input
                                                    type={showPasswd ? 'text' : 'password'}
                                                    name={'password2'}
                                                    className={passwdError == '' ? 'form-control' : 'form-control is-invalid'}
                                                    onChange={onChangeForField}
                                                    required={inputs.password}
                                                />
                                                {passwdError != '' && <span className="text-danger">{passwdError}</span>}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="d-flex align-items-center">
                                                    <input value={showPasswd} onChange={(e) => setShowPasswd(e.target.checked)} type="checkbox" />
                                                    <span className="figure-caption ms-1">Pokaż hasła</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {PermissionHandler(permissions, 'MyData', 'DiscountInfo') &&
                            data &&
                            !['null', 'undefined'].includes(localStorage.getItem('partnerName')) && (
                                <div className="col-xs-6">
                                    <h1 className="text-center">
                                        <span className="badge bg-warning">
                                            <FontAwesomeIcon icon={faMoneyBill} />
                                            &nbsp; Informacja o rabatach
                                        </span>
                                    </h1>
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Kategoria</th>
                                                <th scope="col">Wartość (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataPD?.map(
                                                (x) =>
                                                    x.value > 0 && (
                                                        <tr>
                                                            <td>{discontCategory(x.category)}</td>
                                                            <td>{x.value}</td>
                                                        </tr>
                                                    )
                                            )}
                                            {dataParD?.[0]?.value > 0 && (
                                                <tr>
                                                    <td>Rabat na produkty TZMO</td>
                                                    <td>{dataParD?.[0]?.value}</td>
                                                </tr>
                                            )}
                                            {localStorage.getItem('partnerName') == 'TZMO' ? (
                                                <tr>
                                                    <td>Rabat logistyczny (po osiągnięciu w koszyku)</td>
                                                    <td>2</td>
                                                </tr>
                                            ) : !['CITO Poznań', 'DT', 'INES'].includes(localStorage.getItem('partnerName')) ? (
                                                <tr>
                                                    <td>Rabat za płatność online (po wybraniu metody płatności)</td>
                                                    <td>1</td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                    </div>
                    <div className="w-100 newsletter-fix">
                        {PermissionHandler(permissions, 'Regulations', 'Checked') && inputs.firstName && <RegulationCheckedList />}
                    </div>
                    <div className="w-100 newsletter-fix">
                        {PermissionHandler(permissions, 'Consents', 'Checked') && inputs.firstName && <ConsentCheckedList />}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <input type="submit" className="btn btn-success btn-lg" value="Zapisz" />
                        <ListButton label="Anuluj" to={'/#'} color="info" variant="outlined" />
                    </div>
                </form>
            </CardContent>
        </Card>
    )
}
