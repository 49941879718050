import * as React from 'react'
import { BooleanField, List, TextField, usePermissions } from 'react-admin'
import { CustomActionsButtons } from '../CustomFields/CustomActionButtonsField'

import CustomActionBar from '../CustomElements/CustomActionBar'
import CustomDataGrid from '../CustomElements/CustomDataGrid'
import EmptyList from '../EmptyList'

import PermissionHandler from '../../config/PermissionHandler'

import { CustomPagination } from '../CustomElements/CustomPagination'

const ModuleName = 'Consents'

export default (props) => {
    const { isLoading, permissions } = usePermissions()
    return (
        <List
            {...props}
            pagination={<CustomPagination />}
            empty={<EmptyList title="zgód" add={PermissionHandler(permissions, ModuleName, 'Create')} />}
            actions={<CustomActionBar isAddVisible={PermissionHandler(permissions, ModuleName, 'Create')} />}
            bulkActionButtons={false}
            title={`Zgody`}
        >
            <CustomDataGrid>
                <TextField source="name" label="Nazwa" />
                <TextField source="content" label="Treść" />
                <BooleanField source="status" label="Status" valueLabelTrue="aktywny" valueLabelFalse="nieaktywny" />
                <TextField source="partnerName" label="Partner" emptyText="Wszystkie" />
                {PermissionHandler(permissions, ModuleName, 'Update') && (
                    <CustomActionsButtons
                        label="Akcje"
                        isDeleteAvailable={PermissionHandler(permissions, ModuleName, 'Delete')}
                        isEditAvailable={PermissionHandler(permissions, ModuleName, 'Update')}
                        isDescriptionAvailable={false}
                    />
                )}
            </CustomDataGrid>
        </List>
    )
}
